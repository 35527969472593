import { combineReducers } from 'redux';
import { default as ChangePassReducer, default as ChangeUserPassword } from './ChangePass.reducer';
import GetDashboardData from './Dashboard.reducer';
import FUserChangePassword from './FUserChagePass.reducer';
import GetLicenceKey from './GenerateLicenceKey.reducer';
import GetAllFacilityDetails from './GetFacility.reducer';
import GetReaderDataReducer from './GetManchesterReaderData';
import GetWanderReaderData from './GetWanderReaderData.reducer';
import GetFacilityUserList from './GetfacilityUser.reducer';
import Theme from './Theme';
import UpdateLicenceStatus from './Updatestatus.reducer';
import AddFacility from './addFacility.reducer';
import FacilityUser from './addFacilityUser.reducer';
import AddLicence from './addLicence.reducer';
import AddReaderData from './addReaderDetail.reducer';
import AddUser from './addUser.reducer';
import clothesNotWashedReducer from './clothesNotWashed.reducer';
import DashboardGraphDetailsReducer from './dashboardGraphDetails.reducer';
import DeleteFacilityUser from './deletFacilityUser.reducer';
import DeleteGroup from './deletGroup.reducer';
import DeleteReaderDetial from './deletReaderDetail.reducer';
import DeleteWanderDetail from './deletWanderDetail.reducer';
import deleteUserReducer from './deleteUser.reducer';
import { default as DeleteFacility, default as EditFacility } from './editFacility.reducer';
import EditFacilityUser from './editFacilityUser.reducer';
import EditGroup from './editGroup.reducer';
import EditModuleDetail from './editModuleDetail.reducer';
import EditUserProfile from './editProfile.reducer';
import EditReaderDetail from './editReaderDetail.reducer';
import EditWanderModuleDetail from './editWanderModuleDetail.reducer';
import { default as AddGroup, default as FacilityList } from './facilityList.reducer';
import ForgotPassword from './forgotPassword.reducer';
import GarmentWashReoprt from './garmentwash.reducer';
import getAverageDailyClothsWashReducer from './getAverageDailyClothsWash.reducer';
import getClothItemsReducer from './getClothItems.reducer';
import getContractedHoursReducer from './getContractedHours.reducer';
import getDeliveredClothsReducer from './getDeliveredCloths.reducer';
import getDeliveredClothsForResidentReducer from './getDeliveredClothsForResident.reducer';
import GetFacilityDetailsById from './getFacilityDetailsById.reducer';
import GetFacilityGroupWise from './getFacilityGroupwise.reducer';
import FacilityUserById from './getFacilityUserDetailsById.reducer';
import getGroupDashboardLinenReducer from './getGroupDashboardLinen.reducer';
import GetGroupDetailsById from './getGroupDetailsById.reducer';
import GetGroupList from './getGroupList.reducer';
import getInventoryDataReducer from './getInventoryData.reducer';
import getItemsWashPerHourReducer from './getItemsWashPerHour.reducer';
import getLostItemsReducer from './getLostItems.reducer';
import getMachinesCapacityReducer from './getMachineCapacity.reducer';
import getMonthlyReportData from './getMonthlyReport.reducer';
import GetReaderDetailById from './getReaderDetailsById.reducer';
import getResidenceReducer from './getResidence.reducer';
import getResidentClothListingReducer from './getResidentClothListing.reducer';
import GetScanningModuleData from './getScanningModuleList.reducer';
import getSummaryReportReducer from './getSummaryReport.reducer';
import GetWanderDetailByID from './getWanderDetailsById.reducer';
import getWeeklyReportReducer from './getWeeklyReport.reducer';
import GetStateListReducer from './getstateList.reducer';
import groupAverageWashPerResidentReducer from './groupAverageWashPerResident.reducer';
import groupDashboardProductivityReducer from './groupDashboardProductivity.reducer';
import groupItemsWashedPerHourReducer from './groupItemsWashedPerHour.reducer';
import GroupList from './groupList.reducer';
import groupTrendsReducer from './groupTrends.reducer';
import itemsWashedPerHourPerKgReducer from "./itemsWashedPerHourKg.reducer";
import LedMappingReport from './ledmapping.reducer';
import GetLicence from './licence.reducer';
import LicenceList from './licenceList.reducer';
import linenNotWashedReducer from './linenNotWashed.reducer';
import Login from './login.reducer';
import lowestHighestWashCountReducer from './lowestHighestWashCount.reducer';
import newResidentReducer from './newResident.reducer';
import residentCountForGroupReducer from './residentCountForGroup.reducer';
import residentWashCountReducer from './residentWashCount.reducer';
import residentWiseDeliveredCountReducer from './residentWiseDeliveredCount.reducer';
import ResidentListingReport from './residentlisting.reducer';
import selectedRowDataReducer from './selectedRowData.reducer';
import SetNewPassword from './setNewPassword.reducer';
import summaryBookletReducer from './summaryBooklet.reducer';
import summaryBookletBodyReducer from './summaryBookletBody.reducer';
import topMaxWashCountReducer from './topMaxWashCount.reducer';
import trackedLostItemsReducer from './trackedLostItems.reducer';
import userListReducer from './userList.reducer';
import UserProfile from './userProfile.reducer';
import washCountComparisonReducer from './washCountComparison.reducer';

const reducers = combineReducers({
	theme: Theme,
	userLogin: Login,
	userProfile: UserProfile,
	setNewPassword: SetNewPassword,
	forgotPassword: ForgotPassword,
	userLicenceDetails: GetLicence,
	residentListingReport: ResidentListingReport,
	ledMappingReport: LedMappingReport,
	garmentWashReoprt: GarmentWashReoprt,
	editUserProfile: EditUserProfile,
	addFacility: AddFacility,
	getGroupList: GetGroupList,
	addLicence: AddLicence,
	getFacilityGroupWise: GetFacilityGroupWise,
	licenceList: LicenceList,
	groupList: GroupList,
	facilityList: FacilityList,
	addGroup: AddGroup,
	addUser: AddUser,
	getLicenceKey: GetLicenceKey,
	updateLicenceStatus: UpdateLicenceStatus,
	getAllFacilityDetails: GetAllFacilityDetails,
	getDashboardData: GetDashboardData,
	editGroup: EditGroup,
	editFacility: EditFacility,
	deleteGroup: DeleteGroup,
	deleteFacility: DeleteFacility,
	getFacilityDetailsById: GetFacilityDetailsById,
	getGroupDetailsById: GetGroupDetailsById,
	changeUserPassword: ChangeUserPassword,
	getStateList: GetStateListReducer,
	facilityUser: FacilityUser,
	getFacilityUserList: GetFacilityUserList,
	deleteFacilityUser: DeleteFacilityUser,
	facilityUserById: FacilityUserById,
	editFacilityUser: EditFacilityUser,
	fUserChangePassword: FUserChangePassword,
	getReaderData: GetReaderDataReducer,
	addReaderData: AddReaderData,
	deleteReaderDetial: DeleteReaderDetial,
	getReaderDetailById: GetReaderDetailById,
	editReaderDetail: EditReaderDetail,
	editModuleDetail: EditModuleDetail,
	getWanderReaderData: GetWanderReaderData,
	getScanningModuleData: GetScanningModuleData,
	deleteWanderDetail: DeleteWanderDetail,
	getWanderDetailByID: GetWanderDetailByID,
	editWanderModuleDetail: EditWanderModuleDetail,
	dashboardGraphDetails: DashboardGraphDetailsReducer,
	topMaxWashCountReducer: topMaxWashCountReducer,
	lowestHighestWashCount: lowestHighestWashCountReducer,
	getMonthlyReportData: getMonthlyReportData,
	getResidenceList: getResidenceReducer,
	summaryReport: getSummaryReportReducer,
	residentClothListing: getResidentClothListingReducer,
	lostItemsFound: getLostItemsReducer,
	selectedRowData: selectedRowDataReducer,
	summaryBooklet: summaryBookletReducer,
	summaryBookletBody: summaryBookletBodyReducer,
	deliveredClothsDetails: getDeliveredClothsReducer,
	weeklyReport: getWeeklyReportReducer,
	newResident: newResidentReducer,
	clothesNotWashed: clothesNotWashedReducer,
	deliveredClothes: getDeliveredClothsReducer,
	washCountComparisonReducer: washCountComparisonReducer,
	averageDailyCloths: getAverageDailyClothsWashReducer,
	userList: userListReducer,
	deleteUser: deleteUserReducer,
	changePassword: ChangePassReducer,
	getDeliveredClothsForResident: getDeliveredClothsForResidentReducer,
	residentWiseDeliveredCount: residentWiseDeliveredCountReducer,
	inventoryData: getInventoryDataReducer,
	groupTrends: groupTrendsReducer,
	residentWashCount: residentWashCountReducer,
	residentCountForGroup: residentCountForGroupReducer,
	groupDashboardLinenInventory: getGroupDashboardLinenReducer,
	groupDashboardProductivityReducer: groupDashboardProductivityReducer,
	trackedLostItemsCounts: trackedLostItemsReducer,
	machineCapacity: getMachinesCapacityReducer,
	contractedHours: getContractedHoursReducer,
	clothItems: getClothItemsReducer,
	itemsWashedPerHour: getItemsWashPerHourReducer,
	itemsWashedPerHourPerKg: itemsWashedPerHourPerKgReducer,
	groupItemWashedPerHour: groupItemsWashedPerHourReducer,
	linenNotWashed: linenNotWashedReducer,
	groupAverageWashPerResidentReducer: groupAverageWashPerResidentReducer
});

export default reducers;
