export const LOGIN_SUCCESS = 'login_succees';
export const LOGIN_FAILURE = 'login_failure';

export const FORGOT_PASSWORD_SUCCESS = 'forgot_password_succees';
export const FORGOT_PASSWORD_FAILURE = 'forgot_password_failure';

export const SET_NEW_PASSWORD_SUCCESS = 'set_new_password_succees';
export const SET_NEW_PASSWORD_FAILURE = 'set_new_password_failure';

export const GET_USER_SUCCESS = 'get_user_succees';
export const GET_USER_FAILURE = 'get_user_failure';

export const EDIT_USER_SUCCESS = 'edit_user_succees';
export const EDIT_USER_FAILURE = 'edit_user_failure';

export const GET_LICENCE_SUCCESS = 'get_licence_succees';
export const GET_LICENCE_FAILURE = 'get_licence_failure';

export const GET_GARMENT_WASH_SUCCESS = 'get_garment_wash_succees';
export const GET_GARMENT_WASH_FAILURE = 'get_garment_wash_failure';

export const GET_RESIDENT_LISTING_SUCCESS = 'get_resident_listing_succees';
export const GET_RESIDENT_LISTING_FAILURE = 'get_resident_listing_failure';

export const GET_LED_MAPPING_SUCCESS = 'get_led_mapping_succees';
export const GET_LED_MAPPING_FAILURE = 'get_led_mapping_failure';

export const ADD_FACILITY_SUCCESS = 'add_facility_succees';
export const ADD_FACILITY_FAILURE = 'add_facility_failure';

export const GET_GROUPLIST_SUCCESS = 'get_grouplist_succees';
export const GET_GROUPLIST_FAILURE = 'get_grouplist_failure';

export const ADD_LICENCE_SUCCESS = 'add_licence_succees';
export const ADD_LICENCE_FAILURE = 'add_licence_failure';

export const GET_FACILITY_GROUPWISE_SUCCESS = 'get_facility_groupwise_succees';
export const GET_FACILITY_GROUPWISE_FAILURE = 'get_facility_groupwise_failure';

export const GET_LICENCE_LIST_SUCCESS = 'get_licence_list_succees';
export const GET_LICENCE_LIST_FAILURE = 'get_licence_list_failure';

export const GET_GROUP_LIST_SUCCESS = 'get_group_list_success';
export const GET_GROUP_LIST_FAILURE = 'get_group_list_failure';

export const GET_FACILITY_LIST_SUCCESS = 'get_facility_list_success';
export const GET_FACILITY_LIST_FAILURE = 'get_facility_list_failure';

export const ADD_GROUP_SUCCESS = 'add_group_succees';
export const ADD_GROUP_FAILURE = 'add_group_failure';

export const ADD_USER_SUCCESS = 'add_user_succees';
export const ADD_USER_FAILURE = 'add_user_failure';

export const CHANGE_USER_PASSWORD_SUCCESS = 'change_user_password_succees';
export const CHANGE_USER_PASSWORD_FAILURE = 'change_user_password_failure';

export const DELETE_USER_SUCCESS = 'delete_user_succees';
export const DELETE_USER_FAILURE = 'delete_user_failure';

export const GET_LICENCE_KY_SUCCESS = 'get_licence_key_success';
export const GET_LICENCE_KY_FAILURE = 'get_licence_key_failure';

export const UPDATE_STATUS_SUCCESS = 'update_status_success';
export const UPDATE_STATUS_FAILURE = 'update_status_failure';

export const GET_ALL_FACILITY_LIST_SUCCESS = 'get_all_facility_list_success';
export const GET_ALL_FACILITY_LIST_FAILURE = 'get_all_facility_list_failure';

export const GET_DASHBOARD_SUCCESS = 'get_dashboard_success';
export const GET_DASHBOARD_FAILURE = 'get_dashboard_failure';

export const GET_CLOTHES_NOT_WASHED_SUCCESS = 'GET_CLOTHES_NOT_WASHED_SUCCESS';
export const GET_CLOTHES_NOT_WASHED_FAILURE = 'GET_CLOTHES_NOT_WASHED_SUCCESS';

export const GET_AVERAGE_DAILY_CLOTHS_WASH_SUCCESS = 'GET_AVERAGE_DAILY_CLOTHS_WASH_SUCCESS';
export const GET_AVERAGE_DAILY_CLOTHS_WASH_FAILURE = 'GET_AVERAGE_DAILY_CLOTHS_WASH_FAILURE';

export const EDIT_GROUP_DETAILS_SUCCESS = 'edit_group_details_success';
export const EDIT_GROUP_DETAILS_FAILURE = 'edit_group_details_failure';

export const EDIT_FACILITY_DETAILS_SUCCESS = 'edit_facility_detils_success';
export const EDIT_FACILITY_DETAILS_FAILURE = 'edit_facility_detils_failure';

export const DELETE_GROUP_DETAILS_SUCCESS = 'delete_group_details_success';
export const DELETE_GROUP_DETAILS_FAILURE = 'delete_group_details_failure';

export const DELETE_FACILITY_DETAILS_SUCCESS = 'delete_facility_detils_success';
export const DELETE_FACILITY_DETAILS_FAILURE = 'delete_facility_detils_failure';

export const GET_GROUP_DETIALS_BY_ID_SUCCESS = 'get_group_details_by_id_success';
export const GET_GROUP_DETAILS_BY_ID_FAILURE = 'get_group_details_by_id_failure';

export const GET_FACILITY_DETAILS_BY_ID_SUCCESS = 'get_facility_details_by_id_success';
export const GET_FACILITY_DETAILS_BY_ID_FAILURE = 'get_facility_details_by_id_failure';

export const CHANGE_PASSWORD_SUCCESS = 'change_password_success';
export const CHANGE_PASSWORD_FAILURE = 'change_password_failure';

export const GET_STATELIST_SUCCESS = 'get_state_list_success';
export const GET_STATELIST_FAILURE = 'get_state_list_failure';

export const ADD_FACILITY_USER_SUCCESS = 'add_facility_user_success';
export const ADD_FACILITY_USER_FAILURE = 'add_facility_user_failure';

export const GET_FACILITY_USER_LIST_SUCCESS = 'get_facility_user_list_success';
export const GET_FACILITY_USER_LIST_FAILURE = 'get_facility_user_list_failure';

export const DELETE_FACILITY_USER_SUCCESS = 'delete_facility_User_success';
export const DELETE_FACILITY_USER_FAILURE = 'delete_facility_User_failure';

export const EDIT_FACILITY_USER_SUCCESS = 'edit_facility_User_success';
export const EDIT_FACILITY_USER_FAILURE = 'edit_facility_User_failure';

export const FACILITY_USER_CHANGE_PASSWORD_SUCCESS = 'facility_user_change_password_success';
export const FACILITY_USER_CHANGE_PASSWORD_FAILURE = 'facility_user_change_password_failure';

export const GET_FACILITY_USER_LIST_BY_ID_SUCCESS = 'get_facility_user_list_by_id_success';
export const GET_FACILITY_USER_LIST_BY_ID_FAILURE = 'get_facility_user_list_by_id_failure';

export const GET_MANCHESTER_READER_DATA_SUCCESS = 'get_manchester_reader_data_success';
export const GET_MANCHESTER_READER_DATA_FAILURE = 'get_manchester_reader_data_failure';

export const ADD_READER_DETAIL_SUCCESS = 'add_reader_detail_success';
export const ADD_READER_DETAIL_FAILURE = 'add_reader_detail_failure';

export const DELETE_MANCHESTER_DETAILS_SUCCESS = 'delete_manchester_detail_success';
export const DELETE_MANCHESTER_DETAILS_FAILURE = 'delete_manchester_detail_failure';

export const GET_READER_DETAILS_BY_ID_SUCCESS = 'get_reader_detail_by_id_success';
export const GET_READER_DETAILS_BY_ID_FAILURE = 'get_reader_detail_by_id_failure';

export const EDIT_READER_DETAILS_SUCCESS = 'edit_reader_detail_success';
export const EDIT_READER_DETAILS_FAILURE = 'edit_reader_detail_failure';

export const EDIT_MODULE_DETAILS_SUCCESS = 'edit_module_detail_success';
export const EDIT_MODULE_DETAILS_FAILURE = 'edit_module_detail_failure';

export const GET_WANDER_READER_DATA_SUCCESS = 'get_wander_reader_data_success';
export const GET_WANDER_READER_DATA_FAILURE = 'get_wander_reader_data_failure';

export const GET_SCANNING_MODULE_SUCCESS = 'get_scanning_module_success';
export const GET_SCANNING_MODULE_FAILURE = 'get_scanning_module_failure';

export const DELETE_WANDER_DETAILS_SUCCESS = 'delete_wander_detail_success';
export const DELETE_WANDER_DETAILS_FAILURE = 'delete_wander_detail_failure';

export const GET_WANDER_DETAILS_BY_ID_SUCCESS = 'get_wander_detail_by_id_success';
export const GET_WANDER_DETAILS_BY_ID_FAILURE = 'get_wander_detail_by_id_failure';

export const EDIT_WANDER_MODULE_DETAILS_SUCCESS = 'edit_wander_module_detail_success';
export const EDIT_WANDER_MODULE_DETAILS_FAILURE = 'edit_wander_module_detail_failure';

export const GET_DASHBOARD_GRAPH_DETAILS_SUCCESS = 'GET_DASHBOARD_GRAPH_DETAILS_SUCCESS';
export const GET_DASHBOARD_GRAPH_DETAILS_FAILURE = 'GET_DASHBOARD_GRAPH_DETAILS_FAILURE';

export const GET_DELIVERED_CLOTHES_SUCCESS = 'GET_DELIVERED_CLOTHES_SUCCESS';
export const GET_DELIVERED_CLOTHES_FAILURE = 'GET_DELIVERED_CLOTHES_FAILURE';

export const GET_NEW_RESIDENT_LIST_SUCCESS = 'GET_NEW_RESIDENT_LIST_SUCCESS';
export const GET_NEW_RESIDENT_LIST_FAILURE = 'GET_NEW_RESIDENT_LIST_FAILURE';

export const GET_MAX_FIVE_WASH_COUNT_SUCCESS = 'GET_MAX_FIVE_WASH_COUNT_SUCCESS';
export const GET_MAX_FIVE_WASH_COUNT_FAILURE = 'GET_MAX_FIVE_WASH_COUNT_FAILURE';

export const LOWEST_HIGHEST_WASH_COUNT_SUCCESS = 'LOWEST_HIGHEST_WASH_COUNT_SUCCESS';
export const LOWEST_HIGHEST_WASH_COUNT_FAILURE = 'LOWEST_HIGHEST_WASH_COUNT_FAILURE';

export const GET_MONTHLY_REPORT_SUCCESS = 'GET_MONTHLY_REPORT_SUCCESS';
export const GET_MONTHLY_REPORT_FAILURE = 'GET_MONTHLY_REPORT_FAILURE';

export const GET_WEEKLY_REPORT_SUCCESS = 'GET_WEEKLY_REPORT_SUCCESS';
export const GET_WEEKLY_REPORT_FAILURE = 'GET_WEEKLY_REPORT_FAILURE';
export const CLEAR_WEEKLY_REPORT = 'CLEAR_WEEKLY_REPORT';

export const GET_RESIDENCE_LIST_SUCCESS = 'GET_RESIDENCE_LIST_SUCCESS';
export const GET_RESIDENCE_LIST_FAILURE = 'GET_RESIDENCE_LIST_FAILURE';

export const GET_RESIDENCE_CLOTH_LIST_SUCCESS = 'GET_RESIDENCE_CLOTH_LIST_SUCCESS';
export const GET_RESIDENCE_CLOTH_LIST_FAILURE = 'GET_RESIDENCE_CLOTH_LIST_FAILURE';

export const GET_SUMMARY_REPORT_BY_RESIDENT_SUCCESS = 'GET_SUMMARY_REPORT_BY_RESIDENT_SUCCESS';
export const GET_SUMMARY_REPORT_BY_RESIDENT_FAILURE = 'GET_SUMMARY_REPORT_BY_RESIDENT_FAILURE';

export const GET_LOST_ITEMS_SUCCESS = 'GET_LOST_ITEMS_SUCCESS';
export const GET_LOST_ITEMS_FAILURE = 'GET_LOST_ITEMS_FAILURE';

export const SET_SELECTED_ROW_DATA_SUCCESS = 'SET_SELECTED_ROW_DATA_SUCCESS';
export const SET_SELECTED_ROW_DATA_FAILURE = 'SET_SELECTED_ROW_DATA_FAILURE';

export const SUMMARY_BOOKLET_SUCCESS = 'SUMMARY_BOOKLET_SUCCESS';
export const SUMMARY_BOOKLET_FAILURE = 'SUMMARY_BOOKLET_FAILURE';

export const SUMMARY_BOOKLET_BODY_SUCCESS = 'SUMMARY_BOOKLET_BODY_SUCCESS';
export const SUMMARY_BOOKLET_BODY_FAILURE = 'SUMMARY_BOOKLET_BODY_FAILURE';

export const GET_DELIVERED_CLOTHS_SUCCESS = 'GET_DELIVERED_CLOTHS_SUCCESS';
export const GET_DELIVERED_CLOTHS_FAILURE = 'GET_DELIVERED_CLOTHS_FAILURE';

export const GET_WASH_COUNT_COMPARISON_SUCCESS = 'GET_WASH_COUNT_COMPARISON_SUCCESS';
export const GET_WASH_COUNT_COMPARISON_FAILURE = 'GET_WASH_COUNT_COMPARISON_FAILURE';

export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAILURE = 'GET_USER_LIST_FAILURE';

export const GET_DELIVERED_CLOTHS_FOR_RESIDENT_SUCCESS = 'GET_DELIVERED_CLOTHS_FOR_RESIDENT_SUCCESS';
export const GET_DELIVERED_CLOTHS_FOR_RESIDENT_FAILURE = 'GET_DELIVERED_CLOTHS_FOR_RESIDENT_FAILURE';

export const GET_RESIDENT_WISE_DELIVERED_COUNT_SUCCESS = 'GET_RESIDENT_WISE_DELIVERED_COUNT_SUCCESS';
export const GET_RESIDENT_WISE_DELIVERED_COUNT_FAILURE = 'GET_RESIDENT_WISE_DELIVERED_COUNT_FAILURE';

export const GET_INVENTORY_DATA_SUCCESS = 'GET_INVENTORY_DATA_SUCCESS';
export const GET_INVENTORY_DATA_FAILURE = 'GET_INVENTORY_DATA_FAILURE';

export const GROUP_DASHBOARD_TRENDS_SUCCESS = 'GROUP_DASHBOARD_TRENDS_SUCCESS';
export const GROUP_DASHBOARD_TRENDS_FAILURE = 'GROUP_DASHBOARD_TRENDS_FAILURE';

export const GET_RESIDENT_WASH_COUNT_SUCCESS = 'GET_RESIDENT_WASH_COUNT_SUCCESS';
export const GET_RESIDENT_WASH_COUNT_FAILURE = 'GET_RESIDENT_WASH_COUNT_FAILURE';

export const GET_RESIDENT_COUNT_FOR_GROUP_SUCCESS = 'GET_RESIDENT_COUNT_FOR_GROUP_SUCCESS';
export const GET_RESIDENT_COUNT_FOR_GROUP_FAILURE = 'GET_RESIDENT_COUNT_FOR_GROUP_FAILURE';

export const GET_LINEN_INVENTORY_FOR_GROUP_SUCCESS = 'GET_LINEN_INVENTORY_FOR_GROUP_SUCCESS';
export const GET_LINEN_INVENTORY_FOR_GROUP_FAILURE = 'GET_LINEN_INVENTORY_FOR_GROUP_FAILURE';

export const GROUP_DASHBOARD_PRODUCTIVITY_SUCCESS = 'GROUP_DASHBOARD_PRODUCTIVITY_SUCCESS';
export const GROUP_DASHBOARD_PRODUCTIVITY_FAILURE = 'GROUP_DASHBOARD_PRODUCTIVITY_FAILURE';

export const TRACKED_LOST_ITEMS_COUNT_SUCCESS = 'TRACKED_LOST_ITEMS_COUNT_SUCCESS';
export const TRACKED_LOST_ITEMS_COUNT_FAILURE = 'TRACKED_LOST_ITEMS_COUNT_FAILURE';

export const GET_MACHINES_CAPACITY_SUCCESS = 'GET_MACHINES_CAPACITY_SUCCESS';
export const GET_MACHINES_CAPACITY_FAILURE = 'GET_MACHINES_CAPACITY_FAILURE';

export const GET_CONTRACTED_HOURS_SUCCESS = 'GET_CONTRACTED_HOURS_SUCCESS';
export const GET_CONTRACTED_HOURS_FAILURE = 'GET_CONTRACTED_HOURS_FAILURE';

export const GET_CLOTH_ITEMS_SUCCESS = 'GET_CLOTH_ITEMS_SUCCESS';
export const GET_CLOTH_ITEMS_FAILURE = 'GET_CLOTH_ITEMS_FAILURE';

export const GET_ITEMS_WASHED_PER_HOUR_SUCCESS = 'GET_ITEMS_WASHED_PER_HOUR_SUCCESS';
export const GET_ITEMS_WASHED_PER_HOUR_FAILURE = 'GET_ITEMS_WASHED_PER_HOUR_FAILURE';

export const GET_ITEMS_WASHED_PER_HOUR_PER_KG_SUCCESS = 'GET_ITEMS_WASHED_PER_HOUR_PER_KG_SUCCESS';
export const GET_ITEMS_WASHED_PER_HOUR_PER_KG_FAILURE = 'GET_ITEMS_WASHED_PER_HOUR_PER_KG_FAILURE';

export const GROUP_ITEM_WASHED_PER_HOUR_SUCCESS = 'GROUP_ITEM_WASHED_PER_HOUR_SUCCESS';
export const GROUP_ITEM_WASHED_PER_HOUR_FAILURE = 'GROUP_ITEM_WASHED_PER_HOUR_FAILURE';

export const LINEN_NOT_WASHED_COUNT_SUCCESS = 'LINEN_NOT_WASHED_COUNT_SUCCESS';
export const LINEN_NOT_WASHED_COUNT_FAILURE = 'LINEN_NOT_WASHED_COUNT_FAILURE';

export const GET_AVERAGE_WASH_FOR_GROUP_SUCCESS = 'GET_AVERAGE_WASH_FOR_GROUP_SUCCESS';
export const GET_AVERAGE_WASH_FOR_GROUP_FAILURE = 'GET_AVERAGE_WASH_FOR_GROUP_FAILURE';
